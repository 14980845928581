import { ProductLinks } from "@/types";
import React, { FC } from "react";

interface ApplicationProps {
  icon: string;
  title: string;
  description: string;
  links: ProductLinks;
}

const Application: FC<ApplicationProps> = ({ description, icon, links, title }) => {
  return (
    <div className="col-lg-4 col-md-6 col-sm-6">
      <div className="single-solutions-box">
        <div className="icon">
          <img className="our-work-icon" src={icon} alt={title} />
        </div>
        <h3>{title}</h3>
        <p className="our-work-desc">{description}</p>
        <div className="d-flex justify-content-around">
          {links?.iosLink && (
            <a
              href={links?.iosLink}
              target="_blank"
              rel="noreferrer"
              className="col-lg-2 col-md-3 col-sm-3 p-0"
            >
              <img
                className="app-redirect-icon"
                src="/images/icons/app-store.png"
                alt="app-store"
              />
            </a>
          )}
          {links?.androidLink && (
            <a
              href={links?.androidLink}
              target="_blank"
              rel="noreferrer"
              className="col-lg-2 col-md-3 col-sm-3 p-0"
            >
              <img
                className="app-redirect-icon"
                src="/images/icons/playstore.png"
                alt="app-store"
              />
            </a>
          )}
          {links?.websiteLink && (
            <a
              href={links?.websiteLink}
              target="_blank"
              rel="noreferrer"
              className="col-lg-2 col-md-3 col-sm-3 p-0"
            >
              <img className="app-redirect-icon" src="/images/icons/web-icon.png" alt="app-store" />
            </a>
          )}
        </div>
        <div className="vector-shape6">
          <img src="/images/shape/vector-shape6.png" alt="about" />
        </div>
      </div>
    </div>
  );
};

export default Application;
