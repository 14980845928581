import Portfolio from "@/components/OurWorks";
import { graphql, StaticQuery } from "gatsby";
import React from "react";

export default function OurWorks(): JSX.Element {
  return (
    <StaticQuery
      query={graphql`
        query GET_CONTENT_OF_PORTFOLIO_PAGE {
          contentfulOurWorkPage {
            #SEO
            seo {
              metaTitle
              metaDescription
              metaUrl
              metaAuthor
              metaKeywords
              metaImage {
                file {
                  url
                }
              }
            }

            #PORTFOLIO
            applications {
              id
              applicationTitle
              applicationDescription
              logo {
                file {
                  url
                }
              }

              links {
                iosLink
                androidLink
                websiteLink
              }
            }
            # Footer content
            footerContent {
              raw
            }
          }
        }
      `}
      render={({ contentfulOurWorkPage: { seo, applications, footerContent } }) => (
        <Portfolio props={{ seo, applications, footerContent }} />
      )}
    />
  );
}
