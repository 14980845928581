import { ProductCardOptions, SEOType } from "@/types";
import React, { FC } from "react";
import Layout from "../App/Layout";
import SEO from "../SEO";
import Application from "../Shared/Design/Application";
import BreadCrumb from "../Shared/extra/BreadCrumb";

interface OurWorksProps {
  props: {
    seo: SEOType;
    applications: ProductCardOptions[];
    footerContent?: any;
  };
}

const Portfolio: FC<OurWorksProps> = ({ props: { seo, applications, footerContent } }) => {
  return (
    <Layout footerContent={footerContent}>
      <SEO contentfulSeo={seo} />
      <BreadCrumb
        pageTitle="Zartek Portfolio"
        paths={[
          { path: "/", pathName: "Home" },
          { path: "/our-work/", pathName: "Our Works" },
        ]}
      />

      <section className="solutions-area pt-100 pb-70">
        <div className="container">
          <div className="row">
            {applications.map(
              ({
                id,
                applicationTitle,
                logo: {
                  file: { url },
                },
                links,
                applicationDescription,
              }) => {
                return (
                  <Application
                    key={id}
                    title={applicationTitle}
                    description={applicationDescription}
                    icon={url}
                    links={links}
                  />
                );
              },
            )}
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Portfolio;
